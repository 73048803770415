import React, { useState } from "react";
import App from "../app";
import SEO from "../../components/seo";
import PressLinks from "../../components/presslinks";
import Iframe from "./iframe";
import { useStaticQuery, graphql } from "gatsby";

const Brands = () => {
  let [iframe, setIframe] = useState(false);
  let data = useStaticQuery(
    graphql`
      query {
        allContentfulPress {
          edges {
            node {
              seoTitel
              seoDescription
              node_locale
              heroRubrik1
              heroRubrik2
              heroUnderRubrik1
              heroUnderRubrik2
              heroBild1 {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid
                }
              }
              heroBild2 {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    `
  );

  const OnEventPressBild = e => {
    iframe ? setIframe(false) : setIframe(true);
  };

  return (
    <App>
      <SEO
        title={data.allContentfulPress.edges[0].node.seoTitel}
        description={data.allContentfulPress.edges[0].node.seoDescription}
      />
      {iframe ? null : (
        <PressLinks
          OnEventPressBild={OnEventPressBild}
          iframe={iframe}
          data={data}
        />
      )}
      {iframe ? (
        <Iframe OnEventPressBild={OnEventPressBild} iframe={iframe} />
      ) : null}
    </App>
  );
};

export default Brands;
