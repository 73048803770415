import React from "react";
import { useGlobal } from "reactn";
import * as Styled from "../css/pressStyle";
import BackgroundImage from "gatsby-background-image";
import getLang from "./scripts/getLang";

const PressLinks = ({data}) => {
  let [english] = useGlobal("English");
  let pressLinkData = getLang(english, data.allContentfulPress);
  
  const {
    heroRubrik1,
    heroRubrik2,
    heroUnderRubrik1,
    heroUnderRubrik2,
    heroBild1,
    heroBild2
  } = pressLinkData[0].node; 


  return (
    <Styled.PressContainer>
      <BackgroundImage
        Tag="div"
        className={"pressHero hero"}
        fluid={heroBild1.fluid}
        backgroundColor={`#040e18`}
      >
        <Styled.HeroContent
          className="textDecorationNone"
          href="https://www.mynewsdesk.com/se/stureplansgruppen"
          target="_blank"
        >
          <div>
            <h1>{heroRubrik1}</h1>
            <p>{heroUnderRubrik1}</p>
          </div>
        </Styled.HeroContent>
      </BackgroundImage>

      <BackgroundImage
        Tag="div"
        className={"pressHero hero"}
        fluid={heroBild2.fluid}
        backgroundColor={`#040e18`}
      >
        <Styled.HeroContent
          className="textDecorationNone"
          href="http://press.stureplansgruppen.se/?password-protected=login&redirect_to=http%3A%2F%2Fpress.stureplansgruppen.se%2F"
          target="_blank"
        >
          <div>
            <h1>{heroRubrik2}</h1>
            <p>{heroUnderRubrik2}</p>
          </div>
        </Styled.HeroContent>
      </BackgroundImage>
    </Styled.PressContainer>
  );
};

export default PressLinks;
